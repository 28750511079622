import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Link from 'next/link';
import { useContextualRouting } from 'next-use-contextual-routing';
import { Button } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import { DEFAULT_UNITS } from 'common/constants';
import ControlBarScrollButtons from 'components/ControlBarScrollButtons';
import ForecastDayTable from 'components/ForecastDayTable';
import MissingFavourites from 'components/MissingFavourites';
import { ForecastOutlookContext } from 'contexts/ForecastOutlookContext';
import { PageContext } from 'contexts/PageContext';
import { UserTypeContext } from 'contexts/UserTypeContext';
import type { UserFavorites } from 'types/userFavorites';

import styles from './FavoritesForecastOutlook.module.scss';

interface Props {
  favorites: UserFavorites;
  favoritesError?: boolean;
  isSubregionForecastActive?: boolean;
}

const defaultMissingArgs = {
  title: 'Surf Forecast',
  boldText: 'Looks a little empty in here.',
  normalText: 'Add some favorite spots to compare live conditions and forecasts.',
};

const FavoritesForecastOutlook: React.FC<Props> = ({
  favorites,
  favoritesError,
  isSubregionForecastActive,
}) => {
  const { userType } = useContext(UserTypeContext);
  const { pageName } = useContext(PageContext);
  const { forecasts, requestXForecasts } = useContext(ForecastOutlookContext);
  const [noFavorites, setNoFavorites] = useState(!favorites.length);
  const scrollRef = useRef(null);
  const { makeContextualHref } = useContextualRouting();

  const debouncedScrollHandler = useMemo(
    () =>
      debounce(
        (scrollDirection: string, action: string) => {
          trackEvent('Forecast Range Updated', {
            action,
            pageName,
            scrollDirection,
            uiElement: 'forecast outlook',
          });
        },
        1000,
        {
          leading: true,
          trailing: false,
        },
      ),
    [pageName],
  );

  const debouncedScrollHandlers = useMemo(
    () => ({
      left: (action: string) => debouncedScrollHandler('Left', action),
      right: (action: string) => debouncedScrollHandler('Right', action),
    }),
    [debouncedScrollHandler],
  );

  const onViewAllClicked = useCallback(
    () =>
      trackEvent('Clicked Link', {
        linkName: 'see all',
        linkUrl: '/favorites?type=forecast',
        location: 'home outlook favorites',
        pageName,
      }),
    [pageName],
  );

  const forecastDayTableProps = useMemo(() => {
    let filteredForecasts;
    if (userType.isAnonymous) {
      filteredForecasts = forecasts?.data?.overview?.slice(0, 5);
    } else {
      const isForecastDataAvailable = (forecasts?.data?.overview?.length ?? 0) > 0;
      if (isForecastDataAvailable) {
        filteredForecasts = favorites
          ?.reduce((acc: any[], fav: any) => {
            const forecast = forecasts?.data?.overview?.find(
              (forecastOverview) => fav._id === forecastOverview.spotId,
            );
            if (forecast) {
              acc.push(forecast);
            }
            return acc;
          }, [])
          .slice(0, 5);
      }
    }

    return {
      error:
        favoritesError ||
        (forecasts?.data.overview?.length !== 0 &&
          forecasts?.data.overview?.every((overviewRow) => !!overviewRow.error)),
      forecasts: filteredForecasts,
      isSubregionForecastActive,
      scrollRef,
      units: forecasts?.associated?.units || DEFAULT_UNITS,
    };
  }, [
    favorites,
    favoritesError,
    forecasts?.associated?.units,
    forecasts?.data.overview,
    isSubregionForecastActive,
    userType.isAnonymous,
  ]);

  useEffect(() => {
    if (noFavorites && favorites && favorites?.length >= 1) {
      setNoFavorites(false);
    }
  }, [noFavorites, favorites]);

  useEffect(() => {
    requestXForecasts(favorites?.slice(0, 5), 5);
  }, [requestXForecasts, favorites, forecasts?.data]);

  return noFavorites ? (
    <section className="sl-section-container">
      <MissingFavourites
        title={undefined}
        boldText={defaultMissingArgs.boldText}
        normalText={defaultMissingArgs.normalText}
      />
    </section>
  ) : (
    <section
      className={classNames(styles.outlook, 'sl-section-container')}
      data-testid="sl-favorites-forecast-outlook-container"
    >
      {!noFavorites && (
        <>
          <div className={classNames(styles.headerWrap, styles.headerWrapForecastContent)}>
            <ControlBarScrollButtons
              callToActionButton={undefined}
              debouncedScrollHandlers={debouncedScrollHandlers}
              scrollByPercent={80}
              scrollRef={scrollRef.current}
              shouldScroll
            />
          </div>
          <ForecastDayTable {...forecastDayTableProps} />
          <Link
            href={makeContextualHref({ type: 'forecast' })}
            as="/favorites?type=forecast"
            shallow
            passHref
          >
            <Button
              className={styles.seeAllButton}
              data-testid="favorites-forecast-outlook-see-all-button"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={onViewAllClicked}
              variant="secondary"
            >
              See all
            </Button>
          </Link>
        </>
      )}
    </section>
  );
};

export default FavoritesForecastOutlook;
