import React, { useMemo, useCallback, useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material';
import { getUserFavorites, slugify, trackEvent } from '@surfline/web-common';

import ContentCarousel from 'components/ContentCarousel';
import MissingFavourites from 'components/MissingFavourites';
import SpotPreviewCard from 'components/SpotPreviewCard';
import { SpotPreviewCardProps } from 'components/SpotPreviewCard/SpotPreviewCard';
import { UserTypeContext } from 'contexts/UserTypeContext';
import useFetchUserFavorites from 'hooks/useFetchUserFavorites';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import type { UserFavoritesSWRResponse } from 'types/userFavorites';
import type { ContentCarouselProps } from 'types/contentCarousel';
import { userFavouritesToSpotPreviewCardsTransformer } from 'utils/transformers';
import favoritesFetch, { getPopularSpots } from 'utils/favoritesFetch';

import styles from './FavoritesCarousel.module.scss';

export interface FavoritesCarouselProps {
  makeContextualHref: Function;
  modalOpen?: boolean;
  userCountryCode?: string;
  contentCarouselProps?: Partial<ContentCarouselProps>;
  carouselItemConfig?: {
    alwaysDisplaySmall?: boolean;
    segmentTracking?: (
      title: string,
      cardIndex: number,
    ) => {
      carouselItem: string;
      carouselName: string;
      category: string;
      positionNumber: number;
    };
  };
}

const carouselItem = (
  data: SpotPreviewCardProps,
  modalOpen: boolean,
  index: number,
  onClickHandler: Function,
  isMobileView: boolean,
  alwaysDisplaySmall?: boolean,
) => (
  <SpotPreviewCard
    {...data}
    stopStream={modalOpen}
    cardIndex={index}
    isMobileView={isMobileView}
    onClickHandler={() => onClickHandler(data.title, index, data.id)}
    alwaysDisplaySmall={alwaysDisplaySmall}
    insightsCameraId={data.insightsCameraId}
  />
);

const FavoritesCarousel: React.FC<FavoritesCarouselProps> = ({
  carouselItemConfig,
  contentCarouselProps,
  makeContextualHref,
  modalOpen = false,
  userCountryCode,
}) => {
  const router = useRouter();
  const isMobileView = useMaxWidthTablet();
  const { userType } = useContext(UserTypeContext);
  const selectorFavorites = useSelector(getUserFavorites, shallowEqual);
  const favorites = useFetchUserFavorites(userType, userCountryCode) as UserFavoritesSWRResponse;
  const favoritesData = favorites.data?.data?.favorites;
  const noFavorites = favoritesData ? favoritesData.length < 1 : false;
  const error = !!favorites.error;
  const theme = useTheme();

  const carouselArgs = useMemo(
    () => ({
      title: 'Surf Check',
      colors: {
        bg: theme.palette.common.white,
        skeletonBg: '#2b2a2a',
        skeletonItem: '#333333',
        headerText: theme.palette.text.brandSecondary,
        headerButton: theme.palette.text.primary,
        errorText: theme.palette.text.brandSecondary,
      },
      screenConfig: {
        mobile: 1.1,
        mobileMedium: 1.1,
        mobileLarge: 1.1,
        small: 2,
        medium: 3,
        large: 4,
      },
    }),
    [theme.palette.common.white, theme.palette.text.brandSecondary, theme.palette.text.primary],
  );

  const defaultMissingArgs = {
    title: 'Favorites',
    boldText: 'Welcome to Surfline',
    normalText: 'Lets get started finding you some waves.',
  };

  const onClickCarouselItem = useCallback(
    (title, cardIndex, id) => {
      trackEvent(
        'Carousel Clicked',
        carouselItemConfig?.segmentTracking
          ? carouselItemConfig?.segmentTracking(title, cardIndex)
          : {
              carouselItem: title,
              carouselName: 'Favorites Carousel',
              category: 'kbyg',
              path: router.asPath,
              positionNumber: cardIndex,
              title: 'Home',
            },
      );
      window.location.href = `/surf-report/${slugify(title)}/${id}`;
    },
    [carouselItemConfig, router.asPath],
  );

  const onViewAllClicked = useCallback(
    () =>
      trackEvent('Clicked Link', {
        linkText: 'View all',
        pageName: 'Home',
        linkLocation: 'Favorites Carousel',
        linkName: 'View all',
        path: router.asPath,
        url: `${window?.location.host}${router.asPath}`,
      }),
    [router.asPath],
  );

  const arrowButtonCallback = useCallback(
    (direction: string) => {
      trackEvent('Button Clicked', {
        buttonText: `${direction} Scroll`,
        pageName: 'Home',
        buttonLocation: 'Favorites Carousel',
        buttonName: `${direction} Scroll`,
        path: router.asPath,
        url: `${window?.location.host}${router.asPath}`,
      });
    },
    [router.asPath],
  );

  const callToActionButton = useMemo(
    () => ({
      text: 'View all',
      link: makeContextualHref({ type: 'live' }),
      linkAs: '/favorites?type=live',
      onClickCallback: onViewAllClicked,
    }),
    [makeContextualHref, onViewAllClicked],
  );

  const favoritesCarouselItem = useCallback(
    (data, index) =>
      carouselItem(
        data,
        modalOpen,
        index,
        onClickCarouselItem,
        isMobileView,
        carouselItemConfig?.alwaysDisplaySmall,
      ),
    [carouselItemConfig?.alwaysDisplaySmall, isMobileView, modalOpen, onClickCarouselItem],
  );

  return noFavorites && !error ? (
    <section className="sl-section-container">
      <MissingFavourites
        title={defaultMissingArgs.title}
        boldText={defaultMissingArgs.boldText}
        normalText={defaultMissingArgs.normalText}
      />
    </section>
  ) : (
    <ContentCarousel
      fetchConfig={{
        fetch: userType.isAnonymous ? () => getPopularSpots(userCountryCode) : favoritesFetch,
        endpoint: userType.isAnonymous ? '/kbyg/spots/popular?' : `/kbyg/favorites?`,
      }}
      className={styles.favoritesCarousel}
      callToActionButton={callToActionButton}
      dataTransform={userFavouritesToSpotPreviewCardsTransformer}
      dataTest="favorites-carousel"
      carouselItem={favoritesCarouselItem}
      colors={carouselArgs.colors}
      useSLSectionPadding
      bleedOnDesktop
      reliesOnAspectRatio={false}
      title={carouselArgs.title}
      screenConfig={carouselArgs.screenConfig}
      carouselItemCustomClass={styles.favoritesCarouselItem}
      arrowButtonCallbacks={{
        left: () => arrowButtonCallback('Left'),
        right: () => arrowButtonCallback('Right'),
      }}
      renderOnError
      {...contentCarouselProps}
      shouldMutateData={selectorFavorites}
    />
  );
};

export default FavoritesCarousel;
