const getGreeting = (currentDate: Date) => {
  const currentHour = currentDate.getHours();
  // before noon
  if (currentHour < 12) return 'Good morning';
  // before 5pm
  if (currentHour < 17) return 'Good afternoon';
  // 5pm or later
  return 'Good evening';
};

export default getGreeting;
