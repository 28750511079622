import React, { createContext, useMemo } from 'react';
import { useUserEntitlementStatus } from 'selectors/user';
import { isPremiumUser } from 'utils/user';

export interface UserType {
  isPremium: boolean;
  isFreeRegistered: boolean;
  isAnonymous: boolean;
  isForecastOnlyUser: boolean;
}

interface UserTypeContextValue {
  userType: UserType;
  entitlements?: string[];
  showAds: boolean;
}

export const UserTypeContext = createContext<UserTypeContextValue>({
  userType: {
    isPremium: false,
    isFreeRegistered: true,
    isAnonymous: false,
    isForecastOnlyUser: false,
  },
  entitlements: [],
  showAds: true,
});

interface UserTypeContextProviderProps {
  entitlements?: string[];
  isAnonUser: boolean;
  children: React.ReactNode;
}

const FORECAST_ONLY_ENTITLEMENT = 'sl_forecast_only';
const YOUTH_ENTITLEMENT = 'sl_youth';
const PREMIUM_WITH_ADS_ENTITLEMENT = 'sl_premium_ads';

export const UserTypeContextProvider = ({
  children,
  entitlements,
  isAnonUser,
}: UserTypeContextProviderProps) => {
  const isEntitled = useUserEntitlementStatus();
  const isPremium = isPremiumUser(entitlements);
  const isForecastOnlyUser = entitlements?.includes(FORECAST_ONLY_ENTITLEMENT);
  const isYouth = entitlements?.includes(YOUTH_ENTITLEMENT);
  const isPremiumWithAds = !!entitlements?.includes(PREMIUM_WITH_ADS_ENTITLEMENT);

  const userType = useMemo(
    () => ({
      isPremium: !!isPremium,
      isForecastOnlyUser: !!isForecastOnlyUser,
      isYouth: !!isYouth,
      isPremiumWithAds,
      isFreeRegistered: !isAnonUser && !isPremium && !isForecastOnlyUser,
      isAnonymous: isAnonUser,
    }),
    [isPremium, isForecastOnlyUser, isAnonUser, isYouth, isPremiumWithAds],
  );

  const contextValue = useMemo(
    () => ({ userType, entitlements, showAds: !isEntitled || isPremiumWithAds }),
    [userType, entitlements, isEntitled, isPremiumWithAds],
  );

  return <UserTypeContext.Provider value={contextValue}>{children}</UserTypeContext.Provider>;
};
