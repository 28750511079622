/* istanbul ignore file */
/* eslint-disable @next/next/no-html-link-for-pages */
import type { NextPage } from 'next';
import { SWRConfig } from 'swr';
import { getWindow, nrNoticeError, UserState, type Units } from '@surfline/web-common';

import { fetchUserFavoritesWrapper, getAccessToken, getUser } from 'common/api/user';
import { createParamString, doFetch } from 'common/baseFetch';
import type { PromoboxResponse } from 'components/EditorialCarousel/EditorialCarousel';
import Homepage from 'containers/Homepage';
import { UserTypeContextProvider } from 'contexts/UserTypeContext';
import { getOrCreateStore } from 'stores';
import type { SwellEvents } from 'types/swellEvents';
import type { WavetrakContext } from 'types/wavetrak';
import { getUserGeotarget, getUserCountryCode } from 'utils/user';
import { userToFavouritesResponseTransformer } from 'utils/transformers';
import swellEventsFetch from 'utils/swellEventsFetch';
import { getPopularSpots } from 'utils/favoritesFetch';
import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import { getTreatments } from 'utils/treatments';
import { SL_WEB_OBSERVATION_CLARITY } from 'common/treatments';

export interface WavetrakServerSidePropsContext extends WavetrakContext {
  resolvedUrl: string;
}

interface BasePageProps {
  colorSchemeIndex: number;
  entitlements: string[];
  fallback: Map<string, object>;
  geotarget: string;
  isAnonUser: boolean;
  units: Units;
  userCountryCode: string;
}

const Base: NextPage<BasePageProps> = ({
  colorSchemeIndex,
  entitlements,
  fallback,
  geotarget,
  isAnonUser,
  units,
  userCountryCode,
}) => (
  <SWRConfig value={{ fallback }}>
    <UserTypeContextProvider isAnonUser={isAnonUser} entitlements={entitlements}>
      <Homepage
        colorSchemeIndex={colorSchemeIndex}
        geotarget={geotarget}
        units={units}
        userCountryCode={userCountryCode}
      />
    </UserTypeContextProvider>
  </SWRConfig>
);

export const getServerSideProps = async (context: WavetrakServerSidePropsContext) => {
  const { req, res } = context;
  const isServer = true;
  const user = await getUser(
    {
      ...context,
      pathname: context.resolvedUrl,
    },
    isServer,
  );

  const anonymousID = req?.cookies?.ajs_anonymous_id;

  const treatments =
    anonymousID && isServer
      ? await getTreatments(
          res?.locals?.splitClient,
          [SL_WEB_OBSERVATION_CLARITY],
          user as UserState,
          anonymousID,
        )
      : // eslint-disable-next-line no-underscore-dangle
        getWindow()?.__WAVETRAK_TREATMENTS__;

  if (treatments && treatments[SL_WEB_OBSERVATION_CLARITY] === 'on') {
    const accessToken = getAccessToken(req, res);
    const userId = res?.getHeader('x-auth-userid')?.toString();
    if (userId && accessToken) {
      const favorites = await fetchUserFavoritesWrapper(
        {
          headers: {
            'x-auth-userid': userId,
          },
        },
        {
          query: {
            observationClarity: 'true',
          },
          serverSideCookies: { access_token: accessToken },
        },
        isServer,
      );
      if (user && favorites) user.favorites = favorites.favorites as {}[];
    }
  }

  const requestOptions = {
    headers: { ...addCustomUserAgentHeader(true) },
  };
  const geotarget = getUserGeotarget(user);
  const userCountryCode = getUserCountryCode(user);
  const favoritesFallback = userToFavouritesResponseTransformer(user);

  let editorialsFallback: PromoboxResponse = {
    data: {
      promobox: [],
    },
  };

  let swellEventsFallback: SwellEvents = [];
  const geotargetParamString = createParamString({ geotarget });
  const editorialFetchUrl = `/feed/promobox?${geotargetParamString}`;
  const swellEventsFetchUrl = `/feed/events?${geotargetParamString}`;
  const isAnonUser = !user?.details;

  try {
    editorialsFallback = await doFetch(editorialFetchUrl, requestOptions);
  } catch (err) {
    nrNoticeError(err as Error, {});
  }

  try {
    swellEventsFallback = await swellEventsFetch(swellEventsFetchUrl, requestOptions);
  } catch (err) {
    nrNoticeError(err as Error, {});
  }

  // skip fetching from /spots/popular (aka favorites fallback) endpoint if user is not anonymous
  const spotsFallback = !user?.details
    ? { '/kbyg/spots/popular?': await getPopularSpots(userCountryCode) }
    : {};

  const store = getOrCreateStore();
  const state = store.getState();

  return {
    props: {
      colorSchemeIndex: Math.floor(Math.random() * 6),
      enableCssBaseline: true,
      enableTheme: true,
      entitlements: user?.entitlements,
      fallback: {
        [editorialFetchUrl]: editorialsFallback,
        '/kbyg/favorites?': favoritesFallback,
        [swellEventsFetchUrl]: swellEventsFallback,
        ...spotsFallback,
      },
      geotarget,
      isAnonUser,
      isAppBannerEnabled: true,
      isFavoritesBarVisible: !isAnonUser,
      ssrReduxState: state,
      units: user?.settings?.units,
      userCountryCode,
    },
  };
};

export default Base;
