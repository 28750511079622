import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { trackEvent } from '@surfline/web-common';
import ContentCarousel from 'components/ContentCarousel';
import EditorialCard from 'components/EditorialCard';
import { doFetch, createParamString } from 'common/baseFetch';
import { EditorialCardProps } from 'components/EditorialCard/EditorialCard';
import cx from 'classnames';
import { useTheme } from '@mui/material';
import styles from './EditorialCarousel.module.scss';

interface PromoboxTag {
  name: string;
  url: string;
}

interface PromoboxContent {
  title: string;
}

export interface Promobox {
  content: PromoboxContent;
  permalink: string;
  media: {
    promobox2x: string;
  };
  tags: PromoboxTag[];
  id: number;
}

export interface PromoboxResponse {
  data: {
    promobox: Promobox[];
  };
}

export interface PromoboxSWRResponse {
  data?: PromoboxResponse;
  loading: Boolean;
  error?: Boolean;
}

const transformPromoboxToEditorialCard = (data: PromoboxResponse): EditorialCardProps[] => {
  if (data) {
    return data.data.promobox.map((promobox, index) => {
      const isVideo = promobox.tags?.some((el) => el.name.toLowerCase() === 'video');
      return {
        title: promobox.content.title,
        imageUrl: promobox.media.promobox2x,
        link: promobox.permalink,
        tag: isVideo ? 'Video' : promobox.tags[0].name,
        isVideo,
        cardIndex: index,
        id: promobox.id,
      };
    });
  }
  return [];
};

interface EditorialCarouselProps {
  geotarget: string;
}

const EditorialCarousel: React.FC<EditorialCarouselProps> = ({ geotarget }) => {
  const router = useRouter();
  const endpoint = `/feed/promobox?${createParamString({
    geotarget,
  })}`;

  const theme = useTheme();

  const DEFAULT_COLORS = {
    bg: theme.palette.text.brandSecondary,
    skeletonBg: '#206977',
    skeletonItem: '#1d7b8e',
    headerText: theme.palette.common.white,
    headerSubtitle: theme.palette.text.secondary,
    headerButton: theme.palette.common.white,
    errorText: theme.palette.text.extraLight,
  };

  const createEditorialCard = (itemData: EditorialCardProps, index: number) => (
    <EditorialCard {...itemData} cardIndex={index} />
  );

  const arrowButtonCallback = useCallback(
    (direction: string) => {
      trackEvent('Clicked Button', {
        linkText: `${direction} Scroll`,
        pageName: 'Home',
        linkLocation: 'Editorial Carousel',
        linkName: `${direction} Scroll`,
        path: router.asPath,
        url: `${window?.location.host}${router.asPath}`,
      });
    },
    [router.asPath],
  );

  const onViewAllClicked = () =>
    trackEvent('Clicked Link', {
      linkText: 'View all',
      pageName: 'Home',
      linkLocation: 'Editorial Carousel',
      linkName: 'View all',
      path: router.asPath,
      url: `${window?.location.host}${router.asPath}`,
    });

  return (
    <div className={cx(styles.editorialCarouselBleed, 'sl-section-full-bleed')}>
      <ContentCarousel
        fetchConfig={{
          fetch: doFetch,
          endpoint,
        }}
        className={styles.editorialCarousel}
        carouselItem={createEditorialCard}
        dataTransform={transformPromoboxToEditorialCard}
        colors={DEFAULT_COLORS}
        useSLSectionPadding
        carouselItemCustomClass={styles.editorialCardCarousel}
        title="Stories"
        subtitle="From the lineup"
        callToActionButton={{
          isExternal: true,
          text: 'View all',
          link: '/surf-news',
          isDark: true,
          onClickCallback: onViewAllClicked,
        }}
        arrowButtonCallbacks={{
          left: () => arrowButtonCallback('Left'),
          right: () => arrowButtonCallback('Right'),
        }}
        renderOnError
      />
    </div>
  );
};

export default EditorialCarousel;
